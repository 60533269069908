import React from 'react';
import Main from './Main';
import '../App.css';

function Home() {
  return (
    <div>

      <Main />

    </div>
  );
}

export default Home;
